export const formatDate = (date) => {
  return new Date().toDateString(date);
};

export const formatCurrency = (number, showCurrency = false) => {
  if (showCurrency) {
    return new Intl.NumberFormat("en-US", { style: "currency", currency: "NGN" }).format(number);
  }

  return new Intl.NumberFormat("en-US", { style: "currency", currency: "NGN" }).format(number).replace("NGN", "").trim();
};
