/* eslint-disable no-console */
/* eslint-disable new-cap */
/* eslint-disable no-nested-ternary */
// eslint-disable-next-line no-unused-vars
import React, { useEffect, useState, useContext } from "react";
import HeroBanner from "../../assets/img/transparent-hero.png";
// import {
//   Nav,
//   Tab
// } from "@themesberg/react-bootstrap";
import mobile from "../../assets/img/mobile-images.png";
import qrcode from "../../assets/img/qr-code.png";
import googleplay from "../../assets/img/newgoogleplay.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import appstore from "../../assets/img/newappstore.png";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import {
  faCircleCheck,
  faWarning,
  faHandshake,
  faLocationPin,
  faIdBadge,
  faListCheck,
  faPassport,
  faBuildingColumns,
  faEnvelope,
  faCreditCard,
  faCircleRadiation,
  faReceipt,
  faTrash,
  faClock
} from "@fortawesome/free-solid-svg-icons";
import useAxiosProtected from "../../hooks/useAxiosProtected";
import ApplyNavbar from "../components/ApplyNavbar";
import AppContext from "../../context/AppProvider";
import { formatCurrency } from "../../utils/FormatUtil";
// import { FormatDate } from "../../utils/FormatDate";

export default function Apply() {
  const navigate = useNavigate();
  const axiosProtected = useAxiosProtected();
  const [latestLoan, setLatestLoan] = useState({});
  const [kycStatus, setKycStatus] = useState(false);
  const [isApproving, setIsApproving] = useState(false);
  const [accountToPayTo, setAccountToPayTo] = useState({});
  const { staticConfig } = useContext(AppContext);
  const [tabKey, setTabKey] = useState(0);
  const [noLoanFound, setNoLoanFound] = useState(false);

  const LATEST_LOAN = "/loans/latest";
  const KYC_COMPLETED = "customers/kyc/completed";
  const PARTNER_PRE_APPROVAL = "/loans/partner";

  const handleApplyLink = () => {
    navigate("/apply-loans");
  };

  const handleCompleteKYC = () => {
    navigate("/kyc");
  };

  const handleTabSelect = (key) => {
    setTabKey(key);
  };

  console.log("tab", tabKey);

  const handleAdditionalDocs = () => {
    navigate("/apply/upload", {
      state: latestLoan
    });
  };

  const deleteloanDoc = async (id) => {
    toast.loading("Deleting Doc");
    try {
      const response = await axiosProtected.delete(`/loans/documents/${id}`);
      console.log(response);
      if (response.data) {
        toast.update({ render: "Deleted successfully", type: "success" });
        window.location.reload();
      }
    // eslint-disable-next-line no-empty
    } catch (_error) {
      toast.update("unable to delete");
    }
  };

  const acceptLoanOffer = async (id) => {
    setIsApproving(true);
    axiosProtected.put(`${PARTNER_PRE_APPROVAL}/${id}/accept-pre-approval`).then(() => {
      setIsApproving(false);
      toast.success("Loan Offer Accepted");
      toast.update({ render: "Loan Offer Accepted", type: "success" });
      window.location.reload();
    }).catch(() => {
      setIsApproving(false);
      toast.error("Unable to Accept Loan Offer");
    });
  };

  const handleLatestLoan = async () => {
    try {
      const response = await axiosProtected.get(LATEST_LOAN);
      setLatestLoan(response?.data?.data);
      setAccountToPayTo(response?.data?.data?.accounts?.find((account) => account?.main > 0));
    // eslint-disable-next-line no-empty
    } catch (error) {
      if (error?.response?.status === 404) {
        setNoLoanFound(true);
      }
    }
  };

  const handleKYCheck = () => {
    axiosProtected
      .get(`${KYC_COMPLETED}`)
      .then((res) => {
        if (res?.data?.data?.completed) {
          setKycStatus(true);
        }
      })
      .catch((err) => {
        if (err?.response?.status === 401) {
          navigate("/signin");
        }
      });
  };

  useEffect(() => {
    // eslint-disable-next-line no-unused-vars
    let isMounted = true;
    handleLatestLoan();
    handleKYCheck();

    return () => {
      isMounted = false;
    };
  }, []);

  const kycText = staticConfig?.consumer_app?.find(
    (config) => config?.key === "kyc_text"
  );

  const backgroundCheck = (name) => (
    name === "Proof of address" ? "#CAEBFF69" : name === "BVN verification" ? "#788BFF14" :
      name === "Identity document" ? "#CAEBFF69" : name === "Loan agreement" ? "#00C497" : name === "Disbursement details" ? "#00C497" :
        name === "HR Letter" ? "#17004A1A" : "#17004A1A"
  );

  const iconBackground = (name) => (
    name === "Proof of address" ? "#17004A0F" : name === "BVN verification" ? "#788BFF42" :
      name === "Identity document" ? "#17004A0F" : name === "Loan agreement" ? "#3bcfa8" : name === "Disbursement details" ? "#3bcfa8" :
        name === "HR Letter" ? "#17004A1F" : "#17004A1F"
  );

  const iconColor = (name) => (
    name === "Proof of address" ? "#17004A" : name === "BVN verification" ? "#788BFF" :
      name === "Identity document" ? "#17004A" : name === "Loan agreement" ? "#17004A" : name === "Disbursement details" ? "#17004A" :
        name === "HR Letter" ? "#17004A" : "#17004A"
  );

  const iconImage = (name) => (
    name === "Proof of address" ? faLocationPin : name === "BVN verification" ? faIdBadge :
      name === "Identity document" ? faPassport : name === "Loan agreement" ? faHandshake :
        name === "Disbursement details" ? faBuildingColumns : name === "HR Letter" ? faEnvelope : name === "Direct Debit Mandate" ? faCreditCard : name === "Verify work email" ? faListCheck :
          faCircleRadiation
  );

  const RequirementCard = ({ name, status }) => (
    <div className="loan-requirement-card-container" style={{ backgroundColor: backgroundCheck(name) }}>
      <div className="loan-requirement-icon" style={{ backgroundColor: iconBackground(name), color: iconColor(name) }}>
        <FontAwesomeIcon icon={iconImage(name)} />
      </div>
      <div className="loan-requirement-desc">
        <div className="loan-requirement-name">
          {name}
        </div>
        {status === "pass" ? (
          <div className="loan-requirement-status-pass">
            <FontAwesomeIcon icon={faCircleCheck} />
          </div>
        ) : status === "pending" ? (
          <div className="loan-requirement-status-pending">
            <FontAwesomeIcon icon={faClock} />
          </div>
        ) : null}

      </div>
    </div>
  );

  return (
    <div className="success-wrapper">
      <img src={HeroBanner} alt="" className="success-bg-img" />
      <div className="page-container">
        <ApplyNavbar />
        <div className="success-body">
          <div className="pending-container spacing-pending-container">
            {/* <Tab.Container
              activeKey={tabKey}
              onSelect={(k) => handleTabSelect(k)}
              id="commentsView"
            >
              <Nav className="nav-tabs mb-1">
                <Nav.Item key={1}>
                  <Nav.Link
                    eventKey={1}
                    className="mb-sm-3 mb-md-0"
                  />
                  Bolaji and Sons
                </Nav.Item> */}
            {/* {loan?.partners?.map((partner) => {
                                  return (
                                    <Nav.Item key={partner?.name}>
                                      <Nav.Link
                                        eventKey={partner?.id}
                                        className="mb-sm-3 mb-md-0"
                                      >
                                        {partner?.name}
                                      </Nav.Link>
                                    </Nav.Item>
                                  );
                                })} */}
            {/* </Nav>
              <Tab.Content />
            </Tab.Container> */}

            {/* If loan is not active or there are partners */}
            {(latestLoan?.status !== "active" || latestLoan?.loan_partners?.length > 0) && (

              <Tabs defaultIndex={tabKey} onSelect={handleTabSelect}>

                <TabList>
                  {latestLoan?.loan_partners?.map((loan, i) => (
                    <Tab key={i}>{loan?.name}</Tab>
                  ))}
                </TabList>
                {latestLoan?.loan_partners?.map((loan, i) => (
                  <TabPanel key={i}>
                    <>
                      <div className="pending-container">
                        <div className="card apply-card border-0">
                          <div
                            className={`text-center approval-status-btn status-btn__${loan?.status} mb-4`}
                          >
                            {loan?.status}
                          </div>

                          <div className="item-pair d-flex align-items-center justify-content-between py-1">
                            <p>Loan ID</p>
                            <h6>{latestLoan?.code}</h6>
                          </div>
                          <div className="item-pair d-flex align-items-center justify-content-between py-1">
                            <p>Loan Amount</p>
                            <h5>₦{loan?.amount}</h5>
                          </div>
                          <div className="item-pair d-flex align-items-center justify-content-between py-1">
                            <p>Duration</p>
                            <h6>{loan?.tenure} {loan?.tenure && "Months"}</h6>
                          </div>
                          <div className="loan-requirement-wrapper">
                            {console.log(loan?.requirements)}
                            {loan?.requirements?.map((item, i) => (
                              <RequirementCard key={i} name={item?.name} status={item?.status} />
                            ))}
                          </div>
                          <button
                            onClick={() => acceptLoanOffer(loan?.id)}
                            disabled={loan?.status !== "pre-approved"}
                            className="signup-button complete-kyc-btn mt-5"
                          >
                            {isApproving ? "..." : "ACCEPT LOAN OFFER"}
                          </button>
                        </div>
                      </div>
                    </>
                  </TabPanel>
                ))}

              </Tabs>
            )}
          </div>
          {/* If there are no partners, show this */}
          {latestLoan?.loan_partners?.length < 1 && (
            <>
              {latestLoan?.amount ? (
                <div className="pending-container">
                  <div className="card apply-card border-0">
                    {latestLoan?.status === "awaiting-decision" && !kycStatus ? (
                      <div
                        className={"text-center approval-status-btn status-btn__rejected mb-4"}
                      >
                    Application not submitted, complete kyc to submit
                      </div>
                    ) : (
                      <div
                        className={`text-center approval-status-btn status-btn__${latestLoan?.status} mb-4`}
                      >
                        {latestLoan?.status}
                      </div>
                    )}

                    <div className="item-pair d-flex align-items-center justify-content-between py-1">
                      <p>Loan ID</p>
                      <h6>{latestLoan?.code}</h6>
                    </div>
                    <div className="item-pair d-flex align-items-center justify-content-between py-1">
                      <p>Loan Amount</p>
                      <h5>{latestLoan?.amount}</h5>
                    </div>
                    {/* If pre-approved repayment amount, amount and Duration */}
                    {/* Awaiting decision || Rejected - Amount, Duration */}
                    {/* when it is active, we are showing Payment Due, Amount, Due Date, Account to Pay to, Payment Left */}
                    {latestLoan?.status === "active" ? (
                      <>
                        {latestLoan?.payment_due ? (
                          <div className="item-pair d-flex align-items-center justify-content-between py-1">
                            <p>Payment Due</p>
                            <h5>{latestLoan?.payment_due}</h5>
                          </div>
                        ) : null}
                        {latestLoan?.due_date ? (
                          <div className="item-pair d-flex align-items-center justify-content-between py-1">
                            <p>Due Date</p>
                            <h5>{latestLoan?.due_date?.substring(0, 10)}</h5>
                          </div>
                        ) : null }
                        {latestLoan?.accounts?.length > 1 ? (
                          <div className="item-pair d-flex align-items-center justify-content-between py-1">
                            <p>Account to be pay to</p>
                            <div className="account-paid-to">
                              <h5>{accountToPayTo?.account_number}</h5>
                              <p>{accountToPayTo?.bank}</p>
                            </div>

                            <h5 />
                          </div>
                        ) : null}
                        {latestLoan?.payment_left ? (
                          <div className="item-pair d-flex align-items-center justify-content-between py-1">
                            <p>Left to Pay</p>
                            <h5>{formatCurrency(latestLoan?.payment_left)}</h5>
                          </div>
                        ) : null}
                      </>
                    ) : latestLoan?.status === "pre-approved" ? (
                      <>
                        {latestLoan?.monthly_repayment ? (
                          <div className="item-pair d-flex align-items-center justify-content-between py-1">
                            <p>Repayment Amount</p>
                            <h5>{latestLoan?.monthly_repayment}</h5>
                          </div>
                        ) : null}
                        {latestLoan?.deposit ? (
                          <div className="item-pair d-flex align-items-center justify-content-between py-1">
                            <p>Deposit</p>
                            <h5>{latestLoan?.deposit}</h5>
                          </div>
                        ) : null}
                      </>
                    ) : null}

                    <div className="item-pair d-flex align-items-center justify-content-between py-1">
                      <p>Duration</p>
                      <h6>{latestLoan?.tenure_in_months} {latestLoan?.tenure_in_months && "Months"}</h6>
                    </div>
                  </div>

                  {latestLoan?.status === "rejected" ? (
                    <div className="apply-reason-container">
                      <h6>
                    Unfortunately, it looks like your application was not
                    approved 😟
                      </h6>
                      <p>Reason for Rejection</p>
                      <h3>{latestLoan?.rejection_reason}</h3>
                      <a
                        href="https://blog.monthly.ng/blog/common-reasons-why-your-loan-application-may-have-been-rejected"
                        target="_blank"
                        referrerPolicy="no-referrer" rel="noreferrer"
                      >
                    Read: Common Reasons why your loan application may be
                    rejected
                      </a>
                    </div>
                  ) : latestLoan?.status === "pre-approved" ? (
                    <>
                      <button
                        className="verify-btn mt-3 mb-3 w-100"
                        onClick={handleCompleteKYC}
                      >
                        <div className="verify-icon">
                          <FontAwesomeIcon icon={faHandshake} />
                        </div>
                        <div className="verify-text">
                      Congratulations. Download Monthly NG app to submit
                      requirements and get funds
                        </div>
                        <div style={{ fontSize: "20px" }} />
                      </button>
                    </>
                  ) : (latestLoan?.status !== "pre-approved" && latestLoan?.status !== "active") ? (
                    <>
                      <button
                        className="verify-btn mt-3 mb-3 w-100"
                        onClick={handleCompleteKYC}
                      >
                        <div className="verify-icon">
                          {kycStatus ? (
                            <FontAwesomeIcon icon={faHandshake} />
                          ) : (
                            <FontAwesomeIcon icon={faReceipt} />
                          )}
                        </div>
                        <div className="verify-text">
                          {kycStatus ? (
                            <>
                              <h2>{kycText?.text}</h2>
                            </>
                          ) : (
                            <h2>
                          Application not submitted. Click here to complete KYC
                            </h2>
                          )}
                        </div>
                        <div style={{ fontSize: "20px" }}>
                          {kycStatus ? (
                            <FontAwesomeIcon
                              icon={faCircleCheck}
                              style={{ color: "#17004a" }}
                            />
                          ) : (
                            <FontAwesomeIcon
                              icon={faWarning}
                              style={{ color: "#6c757d" }}
                            />
                          )}
                        </div>
                      </button>
                      {kycStatus && latestLoan?.status !== "active" ? (
                        <button
                          className="verify-btn mt-3 mb-3 w-100"
                          onClick={handleAdditionalDocs}
                        >
                          <div className="verify-icon">
                            <FontAwesomeIcon icon={faReceipt} />
                          </div>
                          <div className="verify-text">
                            <p>
                          Upload any additional documents that would help us
                          confirm your income E.g. Contracts for each income or
                          CAC documents
                            </p>
                          </div>
                          <FontAwesomeIcon
                            icon={faWarning}
                            style={{ color: "#FFC83E" }}
                          />
                        </button>
                      ) : null}
                    </>
                  ) : null}
                  {latestLoan?.documents?.length ? <h5>Additional Documents uploaded</h5> : null}

                  {latestLoan?.documents?.map((doc, i) => {
                    return (
                      <div key={i} className="item-pair d-flex align-items-center justify-content-between py-1" style={{ width: "60%", margin: "auto" }}>
                        <p>{doc?.description}</p>
                        <h5 style={{ cursor: "pointer" }}>
                          <FontAwesomeIcon
                            icon={faTrash}
                            className="text-danger"
                            onClick={() => deleteloanDoc(doc?.id)}
                          />
                          {/* <div className="verify-icon text-danger" onClick={() => deleteloanDoc(doc?.id)}>
                        <FontAwesomeIcon icon={faTrash} />
                      </div> */}
                        </h5>
                      </div>
                    );
                  })}
                </div>
              ) : null}
            </>)}

          {noLoanFound ? (
            <>
              {" "}
              <img
                src={mobile}
                alt="monthly-mobile"
                className="monthly-mobile-image"
              />
              <button className="apply-btn mb-5" onClick={handleApplyLink}>
                            APPLY NOW
              </button>
            </>
          ) : null}

          <div className="scan-title mt-2">
            Scan the QR code below to download <span>Monthly</span>{" "}
            {!latestLoan ?? "and apply"}
          </div>
          <img src={qrcode} alt="qr-code" className="success-qr" />
          <h5 className="small-success-title">
            Download <span>Monthly NG</span> App
          </h5>
          <div className="search-title">
            You can also search <span>Monthly NG</span> on
          </div>
          <div className="download-icons">
            <a
              href="https://play.google.com/store/apps/details?id=ng.monthly.mobile"
              target="_blank" rel="noreferrer"
            >
              <div>
                <img src={googleplay} alt="googleplay" />
              </div>
            </a>

            <a
              href="https://apps.apple.com/ng/app/monthly-ng-quick-rent-loans/id1634279024"
              target="_blank" rel="noreferrer"
            >
              <div>
                <img src={appstore} alt="appstore" />
              </div>
            </a>
          </div>
        </div>
        <ToastContainer />
      </div>
    </div>
  );
}
